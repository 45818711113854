<template>
  <!--begin::Card-->
  <div
    class="card card-flush mb-0"
    data-kt-sticky="true"
    data-kt-sticky-name="subscription-summary"
    data-kt-sticky-offset="{default: false, lg: '200px'}"
    data-kt-sticky-width="{lg: '250px', xl: '300px'}"
    data-kt-sticky-left="auto"
    data-kt-sticky-top="150px"
    data-kt-sticky-animation="false"
    data-kt-sticky-zindex="95"
  >
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2>{{ patientType }}</h2>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0 fs-6">
      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-7">
        <!--begin::Tabs-->
        <p
          v-for="(item, idx) in tabs.items"
          :key="idx"
          class="text-gray-600 mb-4 tab"
          @click="$emit('select', item)"
          :class="{ 'active-tab': tabs.selected === item }"
        >
          <!-- <i class="bi bi-arrow-right-short me-1" v-if="tabs.selected === item"></i> -->
          {{ item }}
        </p>
        <!--end::Tabs-->

        <!--end::Details-->
      </div>
      <!--end::Section-->

      <template v-if="showNewMailBtn">
        <!--begin::Seperator-->
        <div class="separator separator-dashed mb-7"></div>
        <!--end::Seperator-->

        <!--begin::Section-->
        <div class="mb-4">
          <!--begin::Title-->
          <h5 class="mb-4">Actions</h5>
          <!--end::Title-->
        </div>
        <!--end::Section-->

        <!--begin::Actions-->
        <div class="mb-0 d-grid">
          <a @click="sendNewMail" class="btn btn-primary mb-4">
            Send a new mail
          </a>
          <!-- <a @click="deletePatient" class="btn btn-light-danger">
            Delete {{ patientType }}
          </a> -->
        </div>
        <!--end::Actions-->
      </template>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import JwtService from "@/core/services/JwtService";

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNewMailBtn: false,
    };
  },
  emits: ['delete-patient'],
  computed: {
    patientType() {
      return this.patient.clinic ? 'Patient' : 'Prospect';
    },
  },
  created() {
    // No direct mail system from admin dashboard
    this.showNewMailBtn = !JwtService.isSuperAdmin();
  },
  methods: {
    deletePatient() {
      this.$emit('delete-patient');
    },
    sendNewMail() {
      this.$router.push(`/emails/new?to=${this.patient.email}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: 1px solid #dddee4 !important;
}
.tab {
  cursor: pointer;
}
.active-tab {
  font-weight: bolder !important;
  color: #2237F0 !important;
}
</style>
