<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        <slot name="title"></slot>
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Patient Details</h5>
      <form @submit.prevent="savePatient" ref="form">
        <div class="d-flex flex-row row mb-12">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-12 mb-5">
            <label>Sex</label>
            <div class="d-flex flex-row mt-3">
              <div class="form-check form-check-custom form-check-solid me-5">
                <input
                  class="form-check-input"
                  type="radio"
                  name="sex"
                  value="M"
                  v-model="patient.sex"
                  id="sex-m"
                  required
                />
                <label class="form-check-label mb-0" for="sex-m">M</label>
              </div>
              <div class="form-check form-check-custom form-check-solid">
                <input
                  class="form-check-input"
                  type="radio"
                  name="sex"
                  value="F"
                  v-model="patient.sex"
                  id="sex-f"
                />
                <label class="form-check-label mb-0" for="sex-f">F</label>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="lastname">Last name</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="patient.lastname"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="firstname">First name</label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              v-model="patient.firstname"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="birthdate">Birthdate</label>
            <input
              type="date"
              class="form-control"
              id="birthdate"
              v-model="patient.birthdate"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="language">Language</label>
            <select
              class="form-select"
              id="language"
              v-model="patient.language"
              required
            >
              <option>FR</option>
              <option>EN</option>
              <option>ES</option>
            </select>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="patient.email"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="phone">Phone</label>
            <input
              type="tel"
              class="form-control"
              id="phone"
              v-model="patient.phone"
            />
          </div>

          <h5 class="fw-bolder mb-6">Address</h5>
          <div class="d-flex flex-column col-12 mb-5">
            <label for="street">Street</label>
            <input
              type="text"
              class="form-control"
              id="street"
              v-model="patient.address.street"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="zip">ZIP</label>
            <input
              type="text"
              class="form-control"
              id="zip"
              v-model="patient.address.zip"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="city">City</label>
            <input
              type="text"
              class="form-control"
              id="city"
              v-model="patient.address.city"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="state">State</label>
            <input
              type="text"
              class="form-control"
              id="state"
              v-model="patient.address.state"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="country">Country</label>
            <input
              type="text"
              class="form-control"
              id="country"
              v-model="patient.address.country"
            />
          </div>
          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', patient)"
        >Confirm</a
      > -->
      <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from "lodash";

import ApiService from "@/core/services/ApiService";

// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

export default {
  emits: ["confirm", "cancel"],
  components: {
    // vSelect,
  },
  data() {
    return {
      patient: {
        sex: "M",
        firstname: "",
        lastname: "",
        email: "",
        secondaryEmails: [],
        phone: "",
        secondaryPhones: [],
        address: {
          street: "",
          city: "",
          zip: "",
          state: "",
          country: "",
        },
        birthdate: "",
        language: "EN",
        request: null,
        status: "",
        conversion: {
          coldToHot: null,
          hotToPatient: null,
        },
        notes: null,
      },
    };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    savePatient() {
      console.log(JSON.parse(JSON.stringify(this.patient)));

      if (this.patient.id) {
        const config = {
          responseToast: {
            text: "Patient updated successfully."
          }
        };

        ApiService.update("/patients", this.patient.id, this.patient, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "Patient created successfully."
          }
        };

        ApiService.post("/patients", this.patient, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(existingPatient) {
      if (!_.isEmpty(existingPatient, true)) {
        // Date format
        if (existingPatient.birthdate) {
          existingPatient.birthdate = existingPatient.birthdate.substring(0, 10);
        }

        console.log(JSON.parse(JSON.stringify(existingPatient)));
        this.patient = _.cloneDeep(existingPatient);
        console.log(this.patient);
      } else {
        this.patient.status = "PATIENT";
      }
    },
  },
};
</script>
