export default {
    COLD_PROSPECT: {
        label: "Cold Prospect",
        colorClass: "warning",
    },
    HOT_PROSPECT: {
        label: "Hot Prospect",
        colorClass: "danger",
    },
    HOT_PROSPECT_REJECTED_BY_CLINIC: {
        label: "Rejected by Clinic",
        colorClass: "danger",
    },
    PATIENT: {
        label: "Patient",
        colorClass: "info",
    },
};