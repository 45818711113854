<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">Patient Events</h2>
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <!--begin::Operations list-->
      <div v-if="operations.length">
        <!--begin::Operation item-->
        <div
          class="
            operation-item
            py-6
            d-flex
            flex-row
            justify-content-between
            align-items-center
          "
          v-for="(operation, idx) in operations"
          :key="idx"
        >
          <div>
            <p class="text-gray-900 mb-0">{{ operation.type }}</p>
            <p class="text-gray-600 mb-0">
              {{ new Date(operation.start).toLocaleDateString() }}
            </p>
          </div>
          <div>
              <router-link :to="`/operations/${operation.id}`" class="btn btn-light btn-sm"><i class="bi bi-box-arrow-up-right me-1 mb-1"></i>To operation</router-link>
          </div>
        </div>
        <!--end::Operation item-->
      </div>
      <!--end::Operations list-->
      <div v-else>
        <p>No event found.</p>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    operations: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.operation-type {
  font-size: 0.9rem;
}
.operation-item {
  border-top: 1px solid #eff2f5;
  border-bottom: 1px solid #eff2f5;
}
</style>
