<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">{{ patientType }} Emails</h2>
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Email list-->
    <div class="card-body pt-3 px-lg-0">
      <!--begin::Email list item-->
      <div v-if="messages.length">

      <router-link
        v-for="message in messages"
        :key="message.id"
        :to="`/emails/${message.threadId}`"
        class="email-item d-flex flex-column text-dark"
        :class="{ read: isRead(message) }"
      >
        <div class="d-flex flex-row align-items-center row px-10 py-6">
          <!--begin::Author-->
          <div class="mail-author col-3 d-flex flex-row align-items-center">
            <!--begin::Avatar-->
            <div class="symbol symbol-35px me-3">
              <div
                class="symbol-label"
                :class="{
                  'bg-light-info': isRead(message),
                  'bg-info': !isRead(message),
                }"
              >
                <span
                  :class="{
                    'text-info': isRead(message),
                    'text-white': !isRead(message),
                  }"
                >
                  {{ toInitials(message.from) }}
                </span>
              </div>
            </div>
            <!--end::Avatar-->
            <p class="mb-0 fw-bold">
              {{
                message.from.substring(0, message.from.indexOf("&lt;")) ||
                message.from
              }}
            </p>
          </div>
          <!--end::Author-->
          <!--begin::Mail content-->
          <div class="mail-content col-7">
            <p class="mb-0 fw-bolder">{{ message.subject }}</p>
            <p class="mb-0 text-muted" v-html="message.snippet"></p>
          </div>
          <!--end::Mail content-->
          <!--begin::Info-->
          <div
            class="
              mail-info
              col-2
              d-flex
              flex-row
              justify-content-between
              text-end
              align-items-center
            "
          >
            <div class="me-6 mail-thread-count">
              <span class="badge badge-light-info"> 5 </span>
            </div>
            <p class="mb-0 fw-bolder">
              {{ timeAgo.format(new Date(message.date)) }}
            </p>
          </div>
          <!--end::Info-->
        </div>
        <div class="separator"></div>
      </router-link>
      </div>
      <div v-else>
          <p class="ps-9">No email found.</p>
      </div>
      <!--end::Email list item-->
    </div>
    <!--end::Email list-->

    <a
      v-if="messagesLoading"
      @click.prevent="nextPage()"
      href="#"
      class="btn btn-primary disabled"
      disabled
    >
      <span class="spinner-border spinner-border-sm align-middle ms-2" />
      Loading...
    </a>
    <a
      v-else-if="nextPageToken"
      @click.prevent="nextPage()"
      href="#"
      class="btn btn-primary"
    >
      Show more...
    </a>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.log("timeago");
}

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nextPageToken: "",
      messagesLoading: true,
      messages: [],
      timeAgo: null,
    };
  },
  computed: {
    patientType() {
      return this.patient.clinic ? 'Patient' : 'Prospect';
    },
  },
  mounted() {
    this.timeAgo = new TimeAgo("en-US");
    this.fetchEmails();
  },
  methods: {
    onSearchEmail() {
      this.fetchEmails();
    },
    toInitials(str) {
      try {
        return str
          .match(/\b(\w)/g)
          .join("")
          .slice(0, 2)
          .toUpperCase();
      } catch (err) {
        return str.substring(0, 2).toUpperCase();
      }
    },
    nextPage() {
      console.log("nextPage");
      this.messagesLoading = true;
      this.fetchEmails(this.nextPageToken);
    },
    fetchEmails(pageToken) {
      this.messagesLoading = true;

      const params = {
        maxResults: 10,
        // q: `FROM:${this.patient.email}`,
        // q: `{from:${this.patient.email} replyTo:${this.patient.email}} subject:"NEORIS"`,
        q: `{from:${this.patient.email} replyTo:${this.patient.email} to:${this.patient.email}}`,
        pageToken,
      };

      ApiService.query("gmail/threads", { params })
        .then(({ data: { nextPageToken, parsedMessages } }) => {
          if (pageToken) {
            this.messages.push(...parsedMessages);
          } else {
            this.messages = parsedMessages;
          }
          this.nextPageToken = nextPageToken;
          this.messagesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.messagesLoading = false;
        });
    },
    isRead(message) {
      return !message.labels.includes("UNREAD");
    },
  },
};
</script>

<style lang="scss" scoped>
.operation-type {
  font-size: 0.9rem;
}
</style>
