<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">{{ patientType }} Files</h2>
        <a
          class="btn btn-light btn-sm attachment-input-wrapper"
          :class="{ disabled: isUploading }"
        >
          <i class="bi bi-file-earmark-plus me-0 mb-1" />
          Add file
          <input
            type="file"
            multiple
            :disabled="isUploading"
            @change="filesChange($event.target.files)"
            accept="image/*,application/pdf"
            class="input-file"
          />
        </a>
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <div v-if="patientDocuments.length" class="d-flex">
        <File
          v-for="(file, idx) in patientDocuments"
          :key="idx"
          :file-title="file.filename"
          :file-type="mimeTypeToFile(file.mimetype)"
          :shorten-file-title="false"
          @click="openFile(file)"
        />
      </div>
      <div v-else>
        <p>No file found.</p>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import File from "@/components/files/File.vue";

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    patientDocuments: {
      type: Object,
      required: true,
    },
  },
  emits: ["files-uploaded"],
  components: {
    File,
  },
  data() {
    return {
      isUploading: false,
    };
  },
  computed: {
    patientType() {
      return this.patient.clinic ? "Patient" : "Prospect";
    },
  },
  methods: {
    /**
     * Convert a Mime Type to a file type understandable by child components
     * @param {String} type e.g. 'application/pdf'
     * @return {String} e.g. 'pdf'
     */
    mimeTypeToFile(type) {
      if (type === "application/pdf") return "pdf";
      else return "upload";
    },
    /**
     * Show the given attachment in a new tab
     * @param {Object} attachment { title, mimeType, base64 }
     */
    openFile(attachment) {
      ApiService.get(
        `patients/${this.patient.id}/documents`,
        attachment.id
      ).then(({ data }) => {
        console.log(data);
        fetch(`data:${data.mimetype};base64,${data.base64}`)
          .then((res) => res.blob())
          .then((blob) => {
            const blobUri = URL.createObjectURL(blob);
            console.log(blobUri);
            window.open(blobUri, "_blank");
          });
      });
    },
    async filesChange(fileList) {
      this.isUploading = true;

      if (!fileList.length) return;

      for (const file of fileList) {
        await new Promise((resolve, reject) => {
          const fr = new FileReader();

          fr.addEventListener("load", (e) => {
            console.log(file);
            const config = {
              responseToast: {
                text: "File uploaded successfully.",
              },
            };
            ApiService.post(
              `patients/${this.patient.id}/documents`,
              {
                filename: file.name,
                mimetype: file.type,
                content: e.target.result
                  .replace("data:", "")
                  .replace(/^.+,/, ""),
              },
              config
            )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          });

          fr.readAsDataURL(file);
        });
      }

      this.isUploading = false;
      this.$emit("files-uploaded");
    },
  },
};
</script>

<style lang="scss" scoped>
.operation-type {
  font-size: 0.9rem;
}
</style>

<style lang="scss" scoped>
.attachment-input-wrapper {
  position: relative;
  cursor: pointer;

  input[type="file"] {
    cursor: inherit;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
  }
}
</style>