<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">
          {{ patientType }} Details
          <small v-if="reservedForAdmin">
            <br/>#{{patient.id}}
          </small>
        </h2>
        <a @click="$emit('edit')" class="btn btn-light btn-sm"
          ><i class="bi bi-pencil-square me-1 mb-1"></i>Edit</a
        >
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <!--begin::Patient Section-->
      <div class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Personal:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Personal-->
        <div class="d-flex flex-wrap pt-5 mb-8">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Name:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.firstname }} {{ patient.lastname }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Civil:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.sex }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Birthdate:</td>
                <td class="text-gray-800">{{ getPatientBirthdate }}</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Language:</td>
                <td class="text-gray-800">{{ patient.language }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Personal-->
        <div class="separator separator-dashed mb-10"></div>
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Contact:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Contact-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Email:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.email }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Phone:</td>
                <td class="text-gray-800">{{ patient.phone }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Contact-->
        <div class="separator separator-dashed mb-10"></div>
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Address:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Address-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Street:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.address.street }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">City:</td>
                <td class="text-gray-800">{{ patient.address.city }}</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Country:</td>
                <td class="text-gray-800">{{ patient.address.country }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">ZIP:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.address.zip }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">State:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.address.state }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Address-->
        <div class="separator separator-dashed mb-10"></div>
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Status:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Status-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Status:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patientStatusToStr(patient.status) }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr v-if="clinic">
                <td class="text-gray-400 min-w-175px w-175px">Requested Clinic:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ clinic ? clinic.name : "N/C" }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Request:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ patient.request ? patient.request : "none" }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Patient Section-->
          </div>
          <!--end::Column-->

          <template v-if="reservedForAdmin">
            <div class="separator separator-dashed mb-10"></div>
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                mb-4
              "
            >
              <!--begin::Title-->
              <h5 class="mb-0">Prospect history:</h5>
              <!--end::Title-->
            </div>

            <!--begin::Requests Table-->
            <div class="table-responsive">
              <!--begin::Table-->
              <table class="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr
                    class="
                      border-bottom border-gray-200
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                    "
                  >
                    <th class="min-w-150px">Request Type</th>
                    <th class="min-w-150px">Request Clinic</th>
                    <th class="min-w-150px">Request Date</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->

                <!--begin::Table body-->
                <tbody class="fw-bold text-gray-800">
                  <tr v-for="(contactQuestion, idx) in contactQuestions" :key="idx">
                    <td>
                      <label class="w-150px">
                        {{ contactQuestion.logType }}
                        <span v-if="contactQuestion.logType == 'QUESTION'">
                          ({{ contactQuestion.question.title }})
                        </span>
                      </label>
                    </td>
                    <td>
                      <label class="w-150px">{{ contactQuestion.clinic ? contactQuestion.clinic.name : "" }}</label>
                    </td>
                    <td>
                      <label class="w-150px">{{ new Date(contactQuestion.createdAt).toLocaleString() }}</label>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Requests Table-->
          </template>

        </div>
        <!--end::Status-->
        <div class="separator separator-dashed mb-10"></div>
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Notes:</h5>
          <!--end::Title-->
          <a class="btn btn-light-info btn-sm" @click="$emit('save-notes', updatedNotes)"><i class="bi bi-save me-1"></i>Save changes</a>
        </div>

        <!--begin::Status-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <textarea class="form-control" name="patient-notes" v-model="updatedNotes" placeholder="Write your notes here..." rows="10"></textarea>
        </div>
        <!--end::Status-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import dayjs from "dayjs";
import PatientStatus from "@/core/constants/PatientStatus";
import ApiService from "@/core/services/ApiService";

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updatedNotes: null,
      contactQuestions: [],
      clinic: null,
    };
  },
  mounted() {
    this.updatedNotes = this.patient.notes;

    if (!this.patient.clinic || this.patient.status == "HOT_PROSPECT_REJECTED_BY_CLINIC") {
      ApiService.query('contact-question-logs', {
        params: {
          patient: this.patient.id,
          populate: 'question clinic',
          sortBy: 'createdAt:desc',
          limit: 0,
        }
      }).then(({ data }) => {
        this.contactQuestions = data.results;
      });
    }

    if (this.patient.status == "HOT_PROSPECT_REJECTED_BY_CLINIC" && this.patient.clinic) {
      ApiService.get('clinics', this.patient.clinic).then(({ data }) => {
        this.clinic = data;
        console.log("data")
        console.log(data)
      });
    }
  },
  computed: {
    patientType() {
      return this.patient.clinic ? 'Patient' : 'Prospect';
    },
    getPatientBirthdate() {
      return this.patient.birthdate
        ? dayjs(this.patient.birthdate).format("DD/MM/YYYY")
        : "";
    },
    reservedForAdmin() {
      return this.patient &&
        (
          !this.patient.clinic ||
          this.patient.status === 'HOT_PROSPECT_REJECTED_BY_CLINIC'
        );
    },
  },
  methods: {
    toggleEditDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    patientStatusToStr(status) {
      return PatientStatus[status].label;
    },
  },
};
</script>

<style lang="scss" scoped>
.operation-type {
  font-size: 0.9rem;
}
</style>
